import { styled } from "../../stitches.config";
import Icon from "../../components/Icon";
import { CheckPlayerIdResultCode } from "../../pages/api/auth/resultCode";

const CheckPlayerIdMessageContainer = styled("div", {
  display: "flex",
  "& p": {
    fontSize: "0.8rem",
    fontFamily: "$default",
    fontWeight: 500,
    letterSpacing: "-0.0002em",
    lineHeight: "1rem",
    minHeight: "1rem",
    transform: "translateY(0.05rem)", // visual fine-tune
  },
});

function getCheckPlayerIdMessage(resultCode?: CheckPlayerIdResultCode) {
  if (typeof resultCode === "undefined") return undefined;
  switch (resultCode) {
    case CheckPlayerIdResultCode.Available: {
      return "This Sprout ID is available!";
    }
    case CheckPlayerIdResultCode.Taken: {
      return "This Sprout ID is taken. Try another one!";
    }
    case CheckPlayerIdResultCode.InvalidLength: {
      return "Sprout ID has to be 3-30 characters long.";
    }
    case CheckPlayerIdResultCode.InvalidChars: {
      return "Sprout ID can only contain lowercase alphabets, numbers, underscores (_), and hyphens (-).";
    }
  }
}

export function CheckPlayerIdMessage({
  resultCode,
  isChecking,
}: {
  resultCode: CheckPlayerIdResultCode | undefined;
  isChecking: boolean;
}) {
  const msg = isChecking ? "Checking..." : getCheckPlayerIdMessage(resultCode);
  const icon =
    resultCode === CheckPlayerIdResultCode.Available
      ? "CheckCircle"
      : "AlertCircle";
  const color = isChecking
    ? "#957873"
    : resultCode === CheckPlayerIdResultCode.Available
    ? "#3D7F50"
    : "#B95C37";
  return (
    <CheckPlayerIdMessageContainer>
      {isChecking || typeof resultCode === "undefined" ? (
        <></>
      ) : (
        <Icon icon={icon} color={color} style={{ flex: "0 0 1rem" }} />
      )}
      {msg && (
        <p
          style={{
            color,
            marginLeft: isChecking ? "1.25rem" : "0.25rem",
          }}
        >
          {msg}
        </p>
      )}
    </CheckPlayerIdMessageContainer>
  );
}
