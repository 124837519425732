import chroma from "chroma-js";
import { styled } from "../../stitches.config";

const buttonInputTypography = {
  fontFamily: "$default",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  letterSpacing: "-0.006em",
};

export const buttonInputStateTransition = {
  transition: "all 0.2s ease",
};

/** These colors are used to pre-mix the "state-overlay" effect, since
 * transition doesn't work with linear-gradient background (although you
 * can achieve the "state-overlay" effect with it, e.g.
 * "linear-gradient(0deg, rgba(184, 162, 156, 0.1), rgba(184, 162, 156,
 * 0.1)), $teal90"). */
const primaryColor = "#C1F5F6"; // teal90
const secondaryColor = "#F5F1ED"; // paper90
const overlayColor = "#B8A29C"; // paper60

export const ButtonNew = styled("button", {
  ...buttonInputTypography,
  ...buttonInputStateTransition,
  fontWeight: 500,
  color: "$paper10",
  border: "none",
  borderRadius: "$1",
  boxShadow: "inset 0px -2px 0px rgba(113, 81, 78, 0.1)",
  height: "100%",
  padding: "calc($1 * 1.5) $2",
  "&[disabled]": {
    opacity: 0.25,
  },
  variants: {
    color: {
      primary: {
        background: "$teal90",
        "&:hover:not([disabled])": {
          background: chroma.mix(primaryColor, overlayColor, 0.1).css(),
        },
        "&:active:not([disabled])": {
          background: chroma.mix(primaryColor, overlayColor, 0.2).css(),
        },
      },
      secondary: {
        background: "$paper90",
        "&:hover:not([disabled])": {
          background: chroma.mix(secondaryColor, overlayColor, 0.1).css(),
        },
        "&:active:not([disabled])": {
          background: chroma.mix(secondaryColor, overlayColor, 0.2).css(),
        },
      },
    },
  },
});

export const InputNew = styled("input", {
  ...buttonInputTypography,
  ...buttonInputStateTransition,
  fontWeight: 400,
  background: "rgba(35, 26, 26, 0.05)",
  borderRadius: "$2",
  "--inner-shadow": "inset 0px 2px 0px rgba(113, 81, 78, 0.05)",
  boxShadow: "var(--inner-shadow)",
  padding: "0.875rem 0px 0.875rem 1.25rem",
  "&:hover": {
    boxShadow:
      "var(--inner-shadow), 0px 0px 0px 0.03125rem rgba(184, 162, 156, 0.2)",
  },
  "&:focus": {
    /** Cannot use "$paper60" since it would be converted to "--shadows-paper60". */
    boxShadow: "var(--inner-shadow), 0px 0px 0px 0.0625rem #B8A29C",
    outline: "none",
  },
  "&::placeholder": {
    color: "rgba(35, 26, 26, 0.5)",
  },
});

export const Title = styled("h1", {
  fontFamily: "$default",
  fontWeight: 600,
  fontSize: "1.5625rem",
  lineHeight: "2rem",
  letterSpacing: "-0.022em",
  color: "$paper10",
  userSelect: "none",
});

export const SubTitle = styled("h3", {
  fontFamily: "$default",
  fontWeight: "normal",
  fontSize: "0.875rem",
  lineHeight: "1.25rem",
  letterSpacing: "-0.006em",
  color: "$paper10",
  userSelect: "none",
});

export const SubTitleSentence = styled("span", {
  display: "block",
  "&:not(:last-child)": {
    marginBottom: "$2",
  },
});

export const ContinueButton = styled(ButtonNew, {
  display: "block",
  height: "3rem",
  padding: "0.875rem 1.25rem",
  borderRadius: "$2",
});

export const DetailText = styled("p", {
  margin: 0,
  fontFamily: "$default",
  fontWeight: 500,
  fontSize: "0.8rem",
  lineHeight: "1rem",
  letterSpacing: "-0.0002em",
  color: "$paper50",
});
