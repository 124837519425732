import { styled } from "../stitches.config";

const Centered = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "grid",
  placeItems: "center",
  placeContent: "center",
  gridGap: "$2",
  background: "$beige",
});
export default Centered;
