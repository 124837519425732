import type { User } from "@prisma/client";
import type { AuthResponse } from "../pages/api/auth/user";
import { useAtom } from "jotai";
import { isEqual } from "lodash";
import { useEffect } from "react";
import useSWR, { useSWRConfig } from "swr";
import { currentUserAtom } from "../jotai/atoms";

export function useAuth(): {
  user?: User;
  hasFetched: boolean;
  refresh: () => void;
  update: typeof update;
} {
  const { data } = useSWR<AuthResponse>("/api/auth/user", fetcher);
  const { mutate } = useSWRConfig();
  const [currentUser, setCurrentUser] = useAtom(currentUserAtom);

  useEffect(() => {
    if (!data) return;

    if (data.user && currentUser) {
      const user = {
        ...currentUser,
        id: data.user.id,
        playerId: data.user.playerId,
        anonymous: false,
      };

      if (!isEqual(currentUser, user)) {
        setCurrentUser(user);
      }
    }
  });

  return {
    user: data?.user,
    hasFetched: data !== undefined, // brand new page loads start with data undefined
    refresh: () => mutate("/api/auth/user"), // refreshes the data with no mutation params
    update,
  };
}

function update(fields: Partial<User>) {
  return fetch("/api/user/update", {
    method: "POST",
    body: JSON.stringify(fields),
    headers: { "Content-Type": "application/json" },
  }).catch((e) => {
    console.error(e);
    throw e;
  });
}

async function fetcher(url: string): Promise<AuthResponse> {
  const res = await fetch(url);
  return await res.json();
}
