/**
 * These may be used in frontend, so they can't be in files that import
 * prisma.
 */

export enum CheckPlayerIdResultCode {
  Available,
  Taken,
  InvalidLength,
  InvalidChars,
}

export enum SignupResultCode {
  Done,
  Failed,
}

export enum LoginResultCode {
  Done,
  NotSignedUp,
}
