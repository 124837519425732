import * as Sentry from "@sentry/nextjs";
import { Magic } from "magic-sdk";
import { useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { useToasts } from "react-toast-notifications";
import { useSendAnalyticsEvent } from "../analytics/events";
import { useAuth } from "../auth";
import {
  CheckPlayerIdResultCode,
  LoginResultCode,
  SignupResultCode,
} from "../pages/api/auth/resultCode";

export enum AuthStep {
  EnterEmail,
  CheckEmail,
  EnterPlayerId,
  Done,
  Error,
}

/**
 * Flow:
 * 1. Enter email (send authentication link to inbox with Magic.link)
 * 2. Wait for the player to check email (once authentication link is
 *    clicked, the app tries to login, if the player has already signed up,
 *    jump to 4, otherwise to 3)
 * 3. Claim a Sprout ID, aka. sign up. (send entered ID to backend to check
 *    availability and register)
 * 4. Done (refresh app state with `useAuth` in 3 seconds)
 *
 * X. Error (whenever an unexpected error is caught in step 1-4, jump to
 * this step, don't jump to this step for errors that are expected, e.g.
 * Sprout ID taken when clicking "Continue", since to leave this step the
 * player would be brought back to step 1)
 */
export function useAuthFlow({ onSuccess }: { onSuccess?: () => void } = {}) {
  const { addToast } = useToasts();
  const { refresh } = useAuth();
  const sendAnalyticsEvent = useSendAnalyticsEvent();

  const [step, setStep] = useState<AuthStep>(AuthStep.EnterEmail);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [playerId, setPlayerId] = useState("");
  const [playerIdCheckResultCode, setPlayerIdCheckResultCode] = useState<
    CheckPlayerIdResultCode | undefined
  >();
  const emailInput = useRef<HTMLInputElement>(null);
  const playerIdInput = useRef<HTMLInputElement>(null);
  /** This Magic token will be used at both log-in and sign-up. */
  const didTokenRef = useRef("");

  function onEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function onPlayerIdChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPlayerId(e.target.value.toLocaleLowerCase());
  }

  function resetStep() {
    didTokenRef.current = "";
    setIsSubmitting(false);
    setErrorMsg("");
    setEmail("");
    setPlayerId("");
    setPlayerIdCheckResultCode(undefined);
    setStep(AuthStep.EnterEmail);
    emailInput.current?.focus();
  }

  function done() {
    setStep(AuthStep.Done);
    setIsSubmitting(false);
    refresh();

    /** Show the "Done" step dialog for 3 seconds to let the player
     * perceives a transition. */
    setTimeout(() => {
      typeof onSuccess === "function" && onSuccess.call(undefined);
    }, 3000);
  }

  function error(e: unknown) {
    Sentry.captureException(e);
    setErrorMsg((e as { toString: () => string }).toString());
    setStep(AuthStep.Error);
  }

  // Adapted from https://github.com/vercel/next.js/blob/canary/examples/with-magic/pages/login.js
  async function submitEmail(e: React.FormEvent) {
    e.preventDefault();
    setIsSubmitting(true);

    if (errorMsg) setErrorMsg(""); // Reset

    if (email === "") return;
    const body = {
      email,
    };

    // quirk: react 18's batching does not seem to work well
    // with async func. The state update (setStep) could be blocked
    // by the await statement
    flushSync(() => setStep(AuthStep.CheckEmail));

    try {
      const magicKey = process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY;
      if (!magicKey) throw new Error("No Magic Key");

      const magic = new Magic(magicKey);
      const didToken = await magic.auth.loginWithMagicLink({
        ...body,
        showUI: false,
        redirectURI: new URL("/callback", window.location.origin).href,
      });

      if (didToken) didTokenRef.current = didToken;

      const res = await fetch("/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + didToken,
        },
        body: JSON.stringify(body),
      });

      setIsSubmitting(false);

      const resBody = await res.json();
      if (res.status === 200) {
        if (resBody.resultCode === LoginResultCode.Done) {
          sendAnalyticsEvent({
            name: "login",
            userId: resBody.user.id,
          });
          done();
        } else if (resBody.resultCode === LoginResultCode.NotSignedUp) {
          setStep(AuthStep.EnterPlayerId);
        }
      } else {
        throw new Error(resBody.message);
      }
    } catch (e) {
      error(e);
    }
  }

  async function submitPlayerId(e: React.FormEvent) {
    e.preventDefault();
    if (errorMsg) setErrorMsg("");
    if (playerId === "") return;

    try {
      setIsSubmitting(true);
      const res = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + didTokenRef.current,
        },
        body: JSON.stringify({ playerId }),
      });
      setIsSubmitting(false);

      const resBody = await res.json();
      if (res.status === 200) {
        switch (resBody.resultCode) {
          case SignupResultCode.Done: {
            sendAnalyticsEvent({
              name: "signUp",
              userId: resBody.user.id,
            });
            done();
            break;
          }
          case SignupResultCode.Failed: {
            const checkResultCode = resBody.checkPlayerIdResultCode;
            setPlayerIdCheckResultCode(checkResultCode);
            break;
          }
        }
      } else {
        throw new Error(resBody.message);
      }
    } catch (e) {
      error(e);
    }
  }

  function checkPlayerId(e: React.FormEvent) {
    async function checkPlayerIdInner(e: React.FormEvent) {
      e.preventDefault();
      if (errorMsg) setErrorMsg("");
      if (playerId === "") return;

      try {
        setIsSubmitting(true);
        const res = await fetch("/api/auth/checkPlayerId", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + didTokenRef.current,
          },
          body: JSON.stringify({ playerId }),
        });
        setIsSubmitting(false);

        const resBody = await res.json();
        if (res.status === 200) {
          const checkResultCode = resBody.resultCode;
          setPlayerIdCheckResultCode(checkResultCode);
        } else {
          throw new Error(resBody.message);
        }
      } catch (e) {
        error(e);
      }
    }

    /**
     * Clicking "Continue" also triggers a blur, which triggers this, but
     * we want to let submitPlayerId go first if there's one.
     */
    setTimeout(() => {
      checkPlayerIdInner(e);
    }, 100);
  }

  useEffect(() => {
    if (!errorMsg) return;
    addToast(errorMsg, { appearance: "error" });
  }, [addToast, errorMsg]);

  return {
    /** Universal */
    step,
    isSubmitting,
    resetStep,
    errorMsg,
    /** For log-in / sign-up */
    email,
    emailInput,
    onEmailChange,
    submitEmail,
    /** For sign-up */
    playerId,
    playerIdInput,
    playerIdCheckResultCode,
    onPlayerIdChange,
    checkPlayerId,
    submitPlayerId,
  };
}
