import { styled } from "../../stitches.config";

/** The background for `<AuthDialog>`. */
export const AuthOverlay = styled("div", {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 2147483647,
  cursor: "auto",
  variants: {
    translucent: {
      true: {
        background: "rgba(35, 26, 26, 0.1)",
      },
      false: {
        background: "$paper95",
      },
    },
  },
});
